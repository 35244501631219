<template>
  <div style="width: auto">
    <v-row>
      <v-col cols="12">
        <h2 class="custom-title-secondary mb-2">
          Agradecemos pelo envio das informações.
        </h2>
        <p class="custom-normal-text">
          Agora, precisamos que você agende uma conversa com a nossa equipe!
          <br />
          Neste papo, você poderá tirar todas as suas dúvidas e entender como
          será a nossa parceria.
          <br />
          <strong class="text-blue"
            >Qual a melhor data para conversarmos?</strong
          >
        </p>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col cols="12" md="12">
        <diaries @sucessDiaries="next()" :typeId="1"></diaries>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import diaries from '@src/components/diaries/diaries.vue'

export default {
  components: {
    diaries,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    next() {
      this.$store
        .dispatch('auth/updateUser', {
          idStepRegistration: 6,
        })
        .then(() => {
          this.$router.push({ name: 'progressAdmin' })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@design';
.s-quiz-title {
  font-size: 1.5rem;
}
.s-quiz-subheading {
  font-size: 1.3rem;
}
</style>
